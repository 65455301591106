import { useStore } from "@nanostores/preact";
import { FC, useEffect } from "preact/compat";
import { activeToasts } from "stores/toasts";
import { tv, VariantProps } from "tailwind-variants";

const toastStyle = tv({
  base: "m-2 w-80 rounded p-4 cursor-pointer z-50",
  variants: {
    type: {
      success: "bg-green-500",
      error: "bg-red-500 text-white",
      warning: "bg-yellow-500",
      info: "bg-purple-500 text-white",
    },
  },
  defaultVariants: {
    type: "info",
  },
});

const Toast: FC<
  VariantProps<typeof toastStyle> & {
    title: string;
    content: string;
    onClose: () => any;
  }
> = (props) => {
  return (
    <div
      className={toastStyle(props)}
      onClick={() => {
        console.log("close");
        props.onClose();
      }}
    >
      <h4 className="text-md font-bold">{props.title}</h4>
      <p className="text-xs">{props.content}</p>
    </div>
  );
};

const ToastCenter = () => {
  const $toasts = useStore(activeToasts);

  useEffect(() => {
    for (let i = 0; i < $toasts.length; i++) {
      const it = $toasts[i];
      setTimeout(() => {
        const toasts = [...$toasts.values()];

        toasts.splice(toasts.indexOf(it), 1);

        activeToasts.set(toasts);
      }, Date.now() - it.openedAt + 5000 + i * 50);
    }
  }, []);

  return (
    <div className="fixed bottom-0 right-0 m-2">
      {$toasts.map((it) => (
        <Toast
          type={it.type}
          title={it.title}
          content={it.content}
          onClose={() => {
            const toasts = [...$toasts.values()];

            toasts.splice(toasts.indexOf(it), 1);

            activeToasts.set(toasts);
          }}
        />
      ))}
    </div>
  );
};

export default ToastCenter;
